import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderMenu from "./headerMenu"

const HeaderFrontpage = ({}) => (
  <StaticQuery
    query={graphql`
      query {
          site {
            siteMetadata {
              title,
              year
            }
          },
        defaqto: file(relativePath: { eq: "defaqto-stjerner.png" }) {
          childImageSharp{
            fixed(width:136,height:87){
              src
            }
          }
        },
        bil: file(relativePath: { eq: "bilforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        hus: file(relativePath: { eq: "husforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        innbo: file(relativePath: { eq: "innboforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        reise: file(relativePath: { eq: "reiseforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        bat: file(relativePath: { eq: "baatforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        barn: file(relativePath: { eq: "barneforsikring.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        }
      }
   `}
    render={data => (
      <>
        <header id="section-header" className="header moto-section" data-widget="section" data-container="section">
          <div
            className="moto-widget moto-widget-row row-fixed moto-bg-color1_3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
            data-grid-type="sm" data-widget="row" data-spacing="aasa" style={{}} data-bg-position="left top"
            data-draggable-disabled>
            <div className="container-fluid">
              <div className="row" data-container="container">
                <div
                  className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                  data-bg-position="left top">
                  <div data-widget-id="wid_1553424523_8w11g7e5f"
                       className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto "
                       data-widget="spacer" data-preset="default" data-spacing="sasa"
                       data-visible-on="+desktop,tablet,mobile-h,mobile-v">
                    <div className="moto-widget-spacer-block" style={{ height: '5px' }}/>
                  </div>

                  <HeaderMenu/>

                  {/*<div style={{maxHeight:'20px',overflow:'hidden'}} data-widget-id="wid_1553425132_mdl66p8a8"
                       className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto "
                       data-widget="spacer" data-preset="default" data-spacing="laaa"
                       data-visible-on="+desktop,tablet,mobile-h,mobile-v">
                  </div>*/}

                  <div style={{width:'100%',height:'40px'}}></div>

                  <div
                    className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                    data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top"
                    data-draggable-disabled>
                    <div className="container-fluid">
                      <div className="row" data-container="container">
                        <div className="moto-cell col-sm-6 moto-widget moto-widget-row__column"
                             data-container="container" data-widget="row.column" style={{}} data-bg-position="left top">
                          <div
                            className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v"
                            data-animation>
                            <div className="moto-widget-text-content moto-widget-text-editable"><h1
                              className="moto-text_system_3" style={{ textAlign: 'center' }}>Finn de BESTE
                              forsikringene</h1></div>
                          </div>
                          <div
                            className="moto-widget moto-widget-row row-gutter-0 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
                            data-widget="row" data-spacing="sasa" data-grid-type="xs" style={{}}
                            data-bg-position="left top" data-draggable-disabled>
                            <div className="container-fluid">
                              <div className="row" data-container="container">
                                <div className="moto-cell col-xs-6 moto-widget moto-widget-row__column"
                                     data-container="container" data-widget="row.column" style={{}}
                                     data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aaaa"
                                    data-visible-on="mobile-v" data-animation>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_system_13">VURDERT AV EKSPERTER</p></div>
                                  </div>
                                </div>
                                <div className="moto-cell col-xs-6 moto-widget moto-widget-row__column"
                                     data-container="container" data-widget="row.column" style={{}}
                                     data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aaaa"
                                    data-visible-on="mobile-v" data-animation data-draggable-disabled>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_system_4" style={{ textAlign: 'right' }}>i {data.site.siteMetadata.year}</p></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                            data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v"
                            data-animation data-draggable-disabled>
                            <div className="moto-widget-text-content moto-widget-text-editable"><p
                              className="moto-text_system_10" style={{ textAlign: 'center' }}>Vi sammenligner de beste
                              forsikringene i Norge basert på fakta om&nbsp;<u>kvalitet på vilkårene</u> - ikke etter
                              subjektive meninger.</p></div>
                          </div>
                          <div
                            className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                            data-widget="row" data-spacing="aama" data-grid-type="sm" style={{}}
                            data-bg-position="left top">
                            <div className="container-fluid">
                              <div className="row" data-container="container">
                                <div className="moto-cell col-sm-6 moto-widget moto-widget-row__column"
                                     data-container="container" data-widget="row.column" style={{}}
                                     data-bg-position="left top">
                                  <div data-widget-id="wid__button__5c975facc2b28"
                                       className="moto-widget moto-widget-button moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                                       data-widget="button">
                                    <a href="#sammenlign" data-action="page"
                                       className="moto-widget-button-link moto-size-small moto-link"><span
                                      className="fa moto-widget-theme-icon"/> <span
                                      className="moto-widget-button-label">SAMMENLIGN NÅ</span></a>
                                  </div>
                                </div>
                                <div className="moto-cell col-sm-6 moto-widget moto-widget-row__column"
                                     data-container="container" data-widget="row.column" style={{}}
                                     data-bg-position="left top">
                                  <div data-widget-id="wid__button__5c975facc30d4"
                                       className="moto-widget moto-widget-button moto-preset-2 moto-align-center moto-align-center_mobile-h moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
                                       data-widget="button">
                                    {/*<a href="/om-oss/" data-action="page"
                                       className="moto-widget-button-link moto-size-small moto-link"><span
                                      className="fa moto-widget-theme-icon"/> <span
                                      className="moto-widget-button-label">LES MER</span></a>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="moto-cell col-sm-6 moto-widget moto-widget-row__column"
                             data-container="container" data-widget="row.column" style={{}} data-bg-position="center">
                          <div
                            className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                            data-bg-position="left top" data-draggable-disabled>
                            <div className="container-fluid">
                              <div className="row" data-container="container">
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow bounceInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInRight"
                                    style={{ visibility: 'visible', animationName: 'bounceInRight' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a target="_self"
                                                                                                   data-action="page"
                                                                                                   data-id={23}
                                                                                                   className="moto-link frontpageBigLink"
                                                                                                   href="/bil/"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"><span
                                      className="moto-content-image-container"><img className="moto-content-image"
                                                                                    data-path="2019/06/bilforsikring.png"
                                                                                    data-id={205}
                                                                                    alt="De beste bilforsikringene"
                                                                                    title="De beste bilforsikringene" width={100}
                                                                                    height={100}
                                                                                    alt={"Logo"} src={data.bil.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Bilforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow bounceInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInRight"
                                    style={{ visibility: 'visible', animationName: 'bounceInRight' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a href="/hus"
                                                                                                   target="_self"
                                                                                                   data-action="url"
                                                                                                   className="moto-link frontpageBigLink"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"
                                      data-widget="motoimagewidget"><span className="moto-content-image-container"><img
                                      className="moto-content-image" data-path="2019/06/husforsikring.png" data-id={206}
                                      alt="De beste husforsikringene" title="De beste husforsikringene" width={100} height={100}
                                      src={data.hus.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Husforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto bounceInRight"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInRight" data-draggable-disabled
                                    style={{ visibility: 'visible', animationName: 'bounceInRight' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a href="/reise"
                                                                                                   target="_self"
                                                                                                   data-action="url"
                                                                                                   className="moto-link frontpageBigLink"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero cke_widget_element"
                                      data-cke-widget-data="%7B%22classes%22%3A%7B%22moto-spacing-left-zero%22%3A1%2C%22moto-spacing-bottom-zero%22%3A1%2C%22moto-spacing-right-zero%22%3A1%2C%22moto-spacing-top-zero%22%3A1%2C%22moto-content-image-plugin-wrapper%22%3A1%7D%7D"
                                      data-cke-widget-upcasted={1} data-cke-widget-keep-attr={0}
                                      data-widget="motoimagewidget"><span className="moto-content-image-container"><img
                                      className="moto-content-image" data-path="2019/06/reiseforsikring.png"
                                      data-id={207} alt="De beste reiseforsikringene" title="De beste reiseforsikringene" width={100}
                                      height={100} src={data.reise.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Reiseforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                            data-bg-position="left top">
                            <div className="container-fluid">
                              <div className="row" data-container="container">
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow bounceInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInLeft"
                                    style={{ visibility: 'visible', animationName: 'bounceInLeft' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a href="/innbo"
                                                                                                   target="_self"
                                                                                                   data-action="url"
                                                                                                   className="moto-link frontpageBigLink"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"
                                      data-widget="motoimagewidget"><span className="moto-content-image-container"><img
                                      className="moto-content-image" data-path="2019/06/innboforsikring.png"
                                      data-id={209} alt="De beste innboforsikringene" title="De beste innboforsikringene" width={100}
                                      height={100} src={data.innbo.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Innboforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow bounceInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInLeft"
                                    style={{ visibility: 'visible', animationName: 'bounceInLeft' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a href="/barn"
                                                                                                   target="_self"
                                                                                                   data-action="url"
                                                                                                   className="moto-link frontpageBigLink"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"
                                      data-widget="motoimagewidget"><span className="moto-content-image-container"><img
                                      className="moto-content-image" data-path="2019/06/barneforsikring.png"
                                      data-id={208} alt="De beste barneforsikringene" title="De beste barneforsikringene" width={100}
                                      height={100} src={data.barn.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Barneforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default wow bounceInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="aala" data-visible-on="-"
                                    data-animation="bounceInLeft"
                                    style={{ visibility: 'visible', animationName: 'bounceInLeft' }}>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_255" style={{ textAlign: 'center' }}><a href="/bat"
                                                                                                   target="_self"
                                                                                                   data-action="url"
                                                                                                   className="moto-link frontpageBigLink"><span
                                      className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"
                                      data-widget="motoimagewidget"><span className="moto-content-image-container"><img
                                      className="moto-content-image" data-path="2019/06/baatforsikring.png"
                                      data-id={204} alt="De beste båtforsikringene" title="De beste båtforsikringene" width={100}
                                      height={100}  src={data.bat.childImageSharp.fixed.src}/></span></span><br/><span
                                      className="moto-color_custom3"><span
                                      style={{ fontSize: '18px' }}><strong>Båtforsikring</strong></span></span></a></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}}
                            data-bg-position="left top" data-draggable-disabled>
                            <div className="container-fluid">
                              <div className="row" data-container="container">
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-8 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div
                                    className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
                                    data-widget="text" data-preset="default" data-spacing="sama" data-visible-on="-"
                                    data-animation data-draggable-disabled>
                                    <div className="moto-widget-text-content moto-widget-text-editable"><p
                                      className="moto-text_normal">
                                      <span className="moto-color5_5">
                                        Dataene til sammenligningene leveres av det uavhengige og anerkjente ratingselskapet Defaqto Norway.
                                      </span>
                                      {/*<a
                                      data-action="popup" data-popup-id={13} className="moto-link" href="#"><span
                                      className="moto-color5_5">mer</span></a>*/}

                                      <span className="moto-color5_5"/></p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                  style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa"
                                  data-bg-position="left top">
                                  <div data-widget-id="wid_1561919501_jj9eytopo"
                                       className="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
                                       data-widget="image">
                                    <a className="moto-widget-image-link moto-link" href="https://defaqto.no"
                                       target="_blank" data-action="url">
                                      <img data-src="/mt-content/uploads/2019/06/defaqto-stjerner.png"
                                           className="moto-widget-image-picture lazyloaded" data-id={213}
                                           title="Defaqto stjerner - ekspertvurderinger"
                                           alt="Defaqto stjerner er ekspertvurderinger basert på fakta, ikke meninger."
                                           src={data.defaqto.childImageSharp.fixed.src}/>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )}
  />
)

export default HeaderFrontpage
