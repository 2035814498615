// https://magic.reactjs.net/htmltojsx.htm


import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import HeaderFrontpage from "../components/headerFrontpage"
import InsurerListPerCategory from "../components/insurerListPerCategory"
import CountInsuranceProducts from "../components/countInsuranceProducts"
import SEO from "../components/seo"

//import Image from "../components/image"
//import SEO from "../components/seo"

const IndexPage  = ({}) => (

  <StaticQuery
    query={graphql`
    
      query {
          site {
            siteMetadata {
              title,
              year
            }
          },
      
      
        allProviders2020: allBilforsikring2020Csv(sort:{fields: Provider, order: ASC}){
          edges{
            node{
              Provider
            }
          }
        },
        
        allCarInsurances2020: allBilforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },
        
        allChildrenInsurances2020: allBarneforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },
        
        allHouseInsurances2020: allHusforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },

        allTravelInsurances2020: allReiseforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },


        allHomecontentsInsurances2020: allInnboforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },


        allBoatInsurances2020: allFritidsbatforsikring2020Csv(sort:{fields: Product, order: ASC}){
          edges{
            node{
              Product
            }
          }
        },


        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp{
            fixed(width:36,height:36){
              src
            }
          }
        },
        defaqto: file(relativePath: { eq: "defaqto-stjerner.png" }) {
          childImageSharp{
            fixed(width:136,height:87){
              src
            }
          }
        },
        bil: file(relativePath: { eq: "bilforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        hus: file(relativePath: { eq: "husforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        innbo: file(relativePath: { eq: "innboforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        reise: file(relativePath: { eq: "reiseforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        bat: file(relativePath: { eq: "baatforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        barn: file(relativePath: { eq: "barneforsikring-gronn.png" }) {
          childImageSharp{
            fixed(width:100,height:100){
              src
            }
          }
        },
        background2: file(relativePath: { eq: "mt-0756_home_img02.jpg" }) {
          childImageSharp{
            fixed(width:2048,height:576){
              src
            }
          }
        }
      }
   `}
    render={data => (
  <Layout>
    <SEO title={`Best i test ${data.site.siteMetadata.year}: Sammenlign de beste forsikringene`}
         description={`Best i test forsikringer: Vi har vurdert de beste forsikringene ${data.site.siteMetadata.year} basert på fakta om kvalitet på vilkårene. Sammenlign forsikringer fra selskapene som tilbyr de beste dekningene.`}
         schema={`
              {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Bestforsikring.no",
                "item": "https://bestforsikring.no"
              }]
            }
         `}
    />
    <HeaderFrontpage/>

    <section id="section-content" className="content page-9 moto-section" data-widget="section" data-container="section">
      <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
        <div className="container-fluid">
          <div className="row" data-container="container">
            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>Hvorfor er det så vanskelig å sammenligne forsikringer?</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_system_7" style={{textAlign: 'center'}}>- vi gjør det enkelt å sammenligne vilkår</p><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_normal" style={{textAlign: 'center'}}>Norske forsikringsselskaper legger seg sjelden i selen for at vilkårene på deres forsikringsdekninger lett skal la seg sammenligne med konkurrentene - de fleste selskapene vil også hevde&nbsp;at de har de beste vilkårene, og ihvertfall at de er&nbsp;konkurransedyktige i markedet. Vi etterprøver slike påstander!</p></div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="moto-widget moto-widget-row row-fixed moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top">
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lama" data-visible-on="mobile-v" data-animation>
              <div className="moto-widget-text-content moto-widget-text-editable"><a name="sammenlign"></a><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>Best i test {data.site.siteMetadata.year}: Hvilke typer forsikring kan du sammenligne hos oss?</h3></div>
            </div></div>
        </div>
      </div>
    </div>
      <div className="moto-widget moto-widget-row row-fixed moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aala" style={{}} data-bg-position="left top">
        <div className="container-fluid">
          <div className="row" data-container="container">
            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              <div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aala" style={{}} data-bg-position="left top">
                <div className="container-fluid">
                  <div className="row" data-container="container">
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-container moto-container_content_5c9764d61" data-widget="container" data-container="container" data-css-name="moto-container_content_5c9764d61" data-bg-position="left top">
                        <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation>
                          <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/bilforsikring-gronn.png" data-id={215} alt="Sammenlign bilforsikringer" title="Sammenlign bilforsikringer" width={100} height={100} src={data.bil.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Bilforsikringer</span></p></div>
                        </div><div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="row" data-grid-type="xs" data-spacing="sasa" style={{}} data-bg-position="left top"><div className="container-fluid"><div className="row" data-container="container"><div className="moto-cell col-xs-6 moto-widget moto-widget-row__column" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238"><span className="moto-color1_3">Vi har vurdert</span></p></div>
                      </div></div><div className="moto-cell col-xs-6 moto-widget moto-widget-row__column" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable">
                          <p className="moto-text_system_6">
                            <CountInsuranceProducts objList={data.allCarInsurances2020}/>
                          </p></div>
                      </div></div></div></div></div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne bilforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                      </div><div data-widget-id="wid__button__5c9764a4e2377" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link title="Sammenlign bilforsikring" to={"/bil/sammenlign"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                        <br/><br/>
                        <Link title="De beste bilforsikringene" to={"/bil"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste bilforsikringene &raquo;</Link>
                      </div></div></div>
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-container moto-container_content_5c9765ab3" data-widget="container" data-container="container" data-css-name="moto-container_content_5c9765ab3" data-bg-position="left top">
                        <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation>
                          <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/husforsikring-gronn.png" data-id={217} alt="Sammenlign husforsikringer" title="Sammenlign husforsikringer" width={100} height={100} src={data.hus.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Husforsikringer</span></p></div>
                        </div>
                        <div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top">
                          <div className="container-fluid">
                            <div className="row" data-container="container">
                              <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation>
                                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238"><span className="moto-color1_3">Vi har vurdert</span></p></div>
                                </div>
                              </div>
                              <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_6"><CountInsuranceProducts objList={data.allHouseInsurances2020}/></p></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation>
                          <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne husforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                        </div>
                        <div data-widget-id="wid_1553425835_8mmnt722a" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                          <Link title="Sammenlign husforsikring" to={"/hus/sammenlign"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                          <br/><br/>
                          <Link title="De beste husforsikringene" to={"/hus"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste husforsikringene &raquo;</Link>
                        </div>
                      </div>
                    </div><div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                    <div className="moto-widget moto-widget-container moto-container_content_5c9765aa2" data-widget="container" data-container="container" data-css-name="moto-container_content_5c9765aa2" data-bg-position="left top">
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/reiseforsikring-gronn.png" data-id={216} alt="Sammenlign reiseforsikringer" title="Sammenlign reiseforsikringer" width={100} height={100} src={data.reise.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Reiseforsikringer</span></p></div>
                      </div>
                      <div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top" data-draggable-disabled>
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238"><span className="moto-color1_3">Vi har vurdert</span></p></div>
                              </div>
                            </div>
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_6"><CountInsuranceProducts objList={data.allTravelInsurances2020}/></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne reiseforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                      </div>
                      <div data-widget-id="wid_1553425834_0623aq63q" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link title="Sammenlign reiseforsikring" to={"/reise/sammenlign"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                        <br/><br/>
                        <Link title="De beste reiseforsikringene" to={"/reise"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste reiseforsikringene &raquo;</Link>
                      </div>
                    </div>
                  </div></div>
                </div>
              </div><div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aala" style={{}} data-bg-position="left top">
              <div className="container-fluid">
                <div className="row" data-container="container">
                  <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                    <div className="moto-widget moto-widget-container moto-container_content_5d0f8cf21" data-widget="container" data-container="container" data-css-name="moto-container_content_5d0f8cf21" data-bg-position="left top">
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/innboforsikring-gronn.png" data-id={220} alt="Sammenlign innboforsikringer" title="Sammenlign innboforsikringer" width={100} height={100} src={data.innbo.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Innboforsikringer</span></p></div>
                      </div>
                      <div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238">Vi har vurdert</p></div>
                              </div>
                            </div>
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_6"><CountInsuranceProducts objList={data.allHomecontentsInsurances2020}/></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne innboforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                      </div>
                      <div data-widget-id="wid_1561300209_konnh7imb" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link title="Sammenlign innboforsikring" to={"/innbo/sammenlign"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                        <br/><br/>
                        <Link title="De beste innboforsikringene" to={"/innbo"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste innboforsikringene &raquo;</Link>
                      </div>
                    </div>
                  </div>
                  <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                    <div className="moto-widget moto-widget-container moto-container_content_5d0f8cf22" data-widget="container" data-container="container" data-css-name="moto-container_content_5d0f8cf22" data-bg-position="left top">
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/barneforsikring-gronn.png" data-id={218} alt="Sammenlign barneforsikringer" title="Sammenlign barneforsikringer" width={100} height={100} src={data.barn.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Barneforsikringer</span></p></div>
                      </div>
                      <div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238">Vi har vurdert</p></div>
                              </div>
                            </div>
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_6"><CountInsuranceProducts objList={data.allChildrenInsurances2020}/></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne barneforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                      </div>
                      <div data-widget-id="wid_1561300209_e2x4c3c9b" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link title="Sammenlign barneforsikring" to={"/barn/sammenlign"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                        <br/><br/>
                        <Link title="De beste barneforsikringene" to={"/barn"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste barneforsikringene &raquo;</Link>
                      </div>
                    </div>
                  </div>
                  <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                    <div className="moto-widget moto-widget-container moto-container_content_5d0f8cf23" data-widget="container" data-container="container" data-css-name="moto-container_content_5d0f8cf23" data-bg-position="left top">
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="maam" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7"><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-medium moto-spacing-left-zero"><span className="moto-content-image-container"><img className="moto-content-image" data-path="2020/06/baatforsikring-gronn.png" data-id={219} alt="Sammenlign båtforsikringer" title="Sammenlign båtforsikringer" width={100} height={100} src={data.bat.childImageSharp.fixed.src}/></span></span></p><p className="moto-text_system_7"><span className="moto-color4_1">Båtforsikringer</span></p></div>
                      </div>
                      <div className="moto-widget moto-widget-row row-gutter-0 moto-justify-content_bottom moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-grid-type="xs" data-widget="row" data-spacing="sasa" style={{}} data-bg-position="left top" data-draggable-disabled>
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="aaam" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_238">Vi har vurdert</p></div>
                              </div>
                            </div>
                            <div className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_6"><CountInsuranceProducts objList={data.allBoatInsurances2020}/></p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="ammm" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal">Her kan du <strong>sammenligne båtforsikringene</strong> med de beste vilkårene og dekningene.</p></div>
                      </div>
                      <div data-widget-id="wid_1561300209_fxahf055d" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link title="Sammenlign båtforsikring" to={"/bat"} data-action="url" className="moto-widget-button-link moto-size-small moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">SAMMENLIGN ALLE</span></Link>
                        <br/><br/>
                        <Link title="De beste båtforsikringene" to={"/bat"} data-action="url" style={{fontSize:'100%'}} className="moto-link moto-text_system_8">Vis kun de beste båtforsikringene &raquo;</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
              <div className="moto-widget-text-content moto-widget-text-editable"><h2 style={{textAlign: 'center'}} className="moto-text_system_5">Mangler det vurdering av en forsikring?</h2></div>
            </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
              <div className="moto-widget-text-content moto-widget-text-editable"><p style={{textAlign: 'center'}}><span className="moto-color4_3 moto-text_211">Dersom du oppdager at en vurdering mangler for et selskap, for en&nbsp;forsikringskategori vi dekker, send oss gjerne en melding om dette.</span></p></div>
            </div><div data-widget-id="wid__button__5c9764a4e3084" className="moto-widget moto-widget-button moto-preset-4 moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  " data-widget="button">
              <a href="/kontakt-oss/" data-action="page" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">GI OSS BESKJED</span></a>
            </div></div>
          </div>
        </div>
      </div><div className="moto-widget moto-widget-row row-fixed moto-justify-content_center moto-background-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="row" data-spacing="lala" style={{backgroundImage: 'url('+data.background2.childImageSharp.fixed.src+')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} data-bg-position="center" data-bg-image="2016/11/mt-0756_home_img02.jpg" data-draggable-disabled><div className="container-fluid"><div className="row" data-container="container"><div className="moto-cell col-sm-8 moto-widget moto-widget-row__column moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top" data-spacing="mama"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
      <div className="moto-widget-text-content moto-widget-text-editable"><h2 className="moto-text_255">Hva slags type forsikring ønsker du å sammenligne?</h2></div>
    </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-draggable-disabled data-visible-on="mobile-v"><div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_10">Vi sammenligner foreløpig vilkår på bilforsikringer, husforsikringer, reiseforsikringer, innboforsikringer, barneforsikringer og båtforsikringer fra nærmest alle forsikringsselskaper som tilbyr disse forsikringene i Norge.</p><p className="moto-text_system_10"><br /></p><p className="moto-text_system_10">Endringer i de ulike forsikringsselskapenes vilkår som påvirker sammenligningene oppdateres fortløpende.</p></div></div></div><div className="moto-cell col-sm-4 moto-widget moto-widget-row__column" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="-" data-animation data-draggable-disabled>
      <div className="moto-widget-text-content moto-widget-text-editable"><ul><li className="moto-text_system_8"><Link to={"/bil"} target="_self" data-action="url" className="moto-link">Bilforsikringer</Link></li><li className="moto-text_system_8"><Link to={"/hus"} target="_self" data-action="url" className="moto-link">Husforsikringer</Link></li><li className="moto-text_system_8"><Link to={"/reise"} target="_self" data-action="url" className="moto-link">Reiseforsikringer</Link></li><li className="moto-text_system_8"><Link to={"/innbo"} target="_self" data-action="url" className="moto-link">Innboforsikringer</Link></li><li className="moto-text_system_8"><Link to={"/barn"} target="_self" data-action="url" className="moto-link">Barneforsikringer</Link></li><li className="moto-text_system_8"><Link to={"/bat"} target="_self" data-action="url" className="moto-link">Båtforsikringer</Link></li></ul></div>
    </div></div></div></div></div><div className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled><div className="container-fluid"><div className="row" data-container="container"><div className="moto-cell col-sm-12 moto-widget moto-widget-row__column" data-container="container" data-widget="row.column" style={{}} data-bg-position="left top"><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-animation data-draggable-disabled data-visible-on="mobile-v">
      <div className="moto-widget-text-content moto-widget-text-editable"><h2 style={{textAlign: 'center'}} className="moto-text_system_5">Hvilke forsikringsselskaper har vi vurdert?</h2></div>
    </div><div className="moto-widget moto-widget-row moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="saaa" style={{}} data-bg-position="left top">
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          </div>
        </div>
      </div>
    </div><div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top" data-draggable-disabled>
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
              <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal companyStrong">Vi har som mål å dekke alle selskapene som tilbyr forsikringsprodukter innenfor de forsikringstypene vi dekker.</p></div>
            </div></div><div className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation data-draggable-disabled>
            <div className="moto-widget-text-content moto-widget-text-editable">
              <p className="moto-text_normal">
                <InsurerListPerCategory objList={data.allProviders2020} withLinks={true}/>
                {/*<a href="#" target="_self" data-action="url" className="moto-link">
                Gjensidige, If, Tryg, Sparebank 1, Storebrand,&nbsp; DNB, Frende, Eika, Codan, KLP</a> m.fl.*/}
            </p><p className="moto-text_normal"><br /></p></div>
          </div></div>
        </div>
      </div>
    </div></div></div></div></div>

      {/*}
      <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div data-widget-id="wid_1553426893_j1fabu4v1" className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="spacer" data-preset="default" data-spacing="maaa" data-visible-on="+desktop,tablet,mobile-h,mobile-v">
              <div className="moto-widget-spacer-block" style={{height: '15px'}} />
            </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saaa" data-visible-on="mobile-v" data-animation>
            <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}><span className="fa"></span>&nbsp;91%</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>av de som besøker oss er fornøyde</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_normal" style={{textAlign: 'center'}}>91% av våre besøkende synes tjenesten vår er nyttig når en skal vurdere hvor gode vilkår et forsikringsselskap har.</p></div>
          </div></div>
          <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
            <div data-widget-id="wid_1553426890_lma52zo6z" className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="spacer" data-preset="default" data-spacing="maaa" data-visible-on="+desktop,tablet,mobile-h,mobile-v">
              <div className="moto-widget-spacer-block" style={{height: '15px'}} />
            </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
            <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>100+ vurderte</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>forsikringer i det norske markedet</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_normal" style={{textAlign: 'center'}}>Vi gir deg vurdering av over 100 forsikringer i det norske markedet.</p></div>
          </div>
          </div><div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          <div data-widget-id="wid_1561923061_xrb974i3m" className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="spacer" data-preset="default" data-spacing="maaa" data-visible-on="+desktop,tablet,mobile-h,mobile-v">
            <div className="moto-widget-spacer-block" style={{height: '15px'}} />
          </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
          <div className="moto-widget-text-content moto-widget-text-editable"><h3 style={{textAlign: 'center'}} className="moto-text_239">Kontinuerlig</h3><h3 style={{textAlign: 'center'}} className="moto-text_system_12">oppdatert</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_system_7" style={{textAlign: 'center'}}>- vi monitorer endringer fortløpende</p><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_normal" style={{textAlign: 'center'}}>Ved hjelp av Defaqto Norway så monitorerer vi fortløpende vilkårsdokumentene til forsikringsselskapene og oppdaterer sidene våre dersom det kommer endringer.</p></div>
        </div>
        </div><div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
            <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>Ingen</h3><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>subjektive meninger</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_system_7" style={{textAlign: 'center'}}>- hos oss er det fakta som bestemmer</p><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_normal" style={{textAlign: 'center'}}>Folk er gjerne enten veldig fornøyd med sitt forsikringsselskap - eller veldig misfornøyd. Noe som gjør det vanskelig å stole på undersøkelser som spør om "hvor fornøyd er du med ditt forsikringsselskap?"</p></div>
          </div></div></div>
      </div>
    </div>
      <div className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top">
        <div className="container-fluid">
          <div className="row" data-container="container">
            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aaaa" data-visible-on="mobile-v" data-animation>
                <div className="moto-widget-text-content moto-widget-text-editable"><h2 style={{textAlign: 'center'}} className="moto-text_system_5">Hva sier våre brukere?</h2></div>
              </div>
              <div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top">
                <div className="container-fluid">
                  <div className="row" data-container="container">
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="mama" style={{backgroundImage: 'url(/mt-content/uploads/2016/12/mt-0756_home-bg-2.png)', backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} data-bg-image="2016/12/mt-0756_home-bg-2.png" data-bg-position="left bottom">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="asmm" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_10">Fantastisk tjeneste!&nbsp;</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="maaa" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_9"><strong><a target="_self" data-action="url" className="moto-link" href="#">Henrik, Kolbotn</a></strong>&nbsp;-&nbsp;Apr&nbsp;2020</p></div>
                      </div>
                    </div>
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="mama" style={{backgroundImage: 'url(/mt-content/uploads/2016/12/mt-0756_home-bg-2.png)', backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} data-bg-image="2016/12/mt-0756_home-bg-2.png" data-bg-position="left bottom">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="asmm" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_10">Mer transparens hva angår vilkår på forsikringer&nbsp;er på ingen måte&nbsp;i mitt forsikrings-selskaps beste interesse.</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="maaa" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_9"><a target="_self" data-action="url" className="moto-link" href="#">Anonym, Oslo</a>&nbsp;-&nbsp;Mar 2020</p></div>
                      </div>
                    </div>
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="mama" style={{backgroundImage: 'url(/mt-content/uploads/2016/12/mt-0756_home-bg-2.png)', backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} data-bg-image="2016/12/mt-0756_home-bg-2.png" data-bg-position="left bottom">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="asmm" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_10">Hmmmm...</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="maaa" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_9"><a target="_self" data-action="url" className="moto-link" href="#">Ole, Oslo</a>&nbsp;-&nbsp;Feb 2020</p></div>
                      </div>
                    </div>
                    <div className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="maaa" data-bg-position="left top">
                      <div className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="mama" style={{backgroundImage: 'url(/mt-content/uploads/2016/12/mt-0756_home-bg-2.png)', backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} data-bg-image="2016/12/mt-0756_home-bg-2.png" data-bg-position="left bottom">
                        <div className="container-fluid">
                          <div className="row" data-container="container">
                            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                              <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-small moto-spacing-bottom-medium moto-spacing-left-medium" data-widget="text" data-preset="default" data-spacing="asmm" data-visible-on="mobile-v" data-animation>
                                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_10">Dette har jeg ikke noe tro på. Mye bedre om dere bruker tiden deres på andre ting!</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="maaa" data-visible-on="mobile-v" data-animation>
                        <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_9"><a target="_self" data-action="url" className="moto-link" href="#">Henrik, Kolbotn</a>&nbsp;-&nbsp;Jan 2020</p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {*/}

    </section>



  </Layout>
    )}
  />
)

export default IndexPage
