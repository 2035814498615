import React from "react"
import PropTypes from "prop-types"

const InsurerListPerCategory = ({objList},{withLinks}) => (
  <>
    {getInsurersFromQueryResult(objList).map((forsikring,i)=> (
      <span key={'insurerList'+i}>
        {withLinks != false && <a href={"/" + slugifier(forsikring)} data-action="url" className="moto-link">{forsikring}</a>}
        {withLinks == false && <>{forsikring}</>}
        {i < getInsurersFromQueryResult(objList).length - 2 && <>, </>}
        {i == getInsurersFromQueryResult(objList).length - 2 && <> og </>}
      </span>
    ))}
  </>
)
export default InsurerListPerCategory

InsurerListPerCategory.InsurerListPerCategory = {
  withLinks: PropTypes.bool,
}

InsurerListPerCategory.InsurerListPerCategory = {
  withLinks: false,
}


function getInsurersFromQueryResult(inp){
  let retArr = [];
  let i = 0;
  inp.edges.map(function ({node: fors}) {
    return (retArr.indexOf(fors.Provider) === -1 ? retArr.push(fors.Provider):i=1);
  });

  return retArr;
}

function slugifier(input) {

  let slugString = input;

  return slugString
  //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}