import React from "react"

const CountInsuranceProducts = ({objList}) => (
  <>
    {countInsurancesFromQueryResult(objList)} stk
  </>
)
export default CountInsuranceProducts


function countInsurancesFromQueryResult(inp){
  let retArr = [];
  let i = 0;
  inp.edges.map(function ({node: fors}) {
    i+=1;
  });

  return i;
}